import React, { useEffect } from 'react'
import '../assets/css/Imprint.css'
import imprintBanner from '../assets/images/imprint-banner-img.png'



export default function Imprint() {


    // useEffect(() => {
    //     // Load the Usercentrics scripts
    //     loadScript('https://app.eu.usercentrics.eu/browser-ui/latest/loader.js', 'usercentrics-cmp');
    //     loadScript('https://sdp.eu.usercentrics.eu/latest/uc-block.bundle.js', 'uc-block');

    //     // Add the inline script to suppress the CMP display
    //     loadInlineScript('var UC_UI_SUPPRESS_CMP_DISPLAY=true;');
    // }, []);
    return (
        <>

            {/* header section */}
            <div className='imprint-banner' style={{ height: '250px' }}>
                <div className='text-center text-white container' style={{ paddingTop: '130px', position: 'relative', zIndex: 10 }}>
                    <h2 className='text-uppercase'>Impressum</h2>
                    {/* <img src={imprintBanner} className='text-line' style={{
                        float: 'right',
                        marginTop: '-101px',
                        width: '159px',
                        marginRight: '131px'
                    }} /> */}
                </div>
            </div>

            <div className='container imprint-section p-5 text-white'>
                <div className=''>
                    <h3>Angaben gemäß § 5 TMG</h3>
                    <p>ZOGI Nature GmbH<br />
                        Gutenbergstraße 2<br />
                        75210 Keltern</p>
                </div>
                <div>
                    <p>Handelsregister: HRB 732851<br />
                        Registergericht: Mannheim</p>
                </div>
                <div>
                    <p style={{ color: '#B9D738', textTransform: 'uppercase' }}>Vertreten durch:</p>
                    <p>Geschäftsführer: Jörg Herzog, Kay Eichenberger</p>
                </div>

                <div>
                    <h3>Kontakt</h3>
                    <p>Telefon: +49 7236 98286 - 0<br />
                        E-Mail: hello@zogi-nature.de</p>
                </div>

                <div>
                    <h3>Umsatzsteuer-ID</h3>
                    <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
                        DE322611267</p>
                </div>

                <div>
                    <h3>Redaktionell verantwortlich</h3>
                    <p>ZOGI Nature GmbH<br />
                        Gutenbergstraße 2<br />
                        75210 Keltern</p>
                </div>

                <div>
                    <h3>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h3>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle<br />
                        teilzunehmen.</p>
                </div>
            </div>
        </>
    )
}


const loadScript = (src, id, async = true, type = "application/javascript") => {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = async;
    script.type = type;
    document.head.appendChild(script);
};

const loadInlineScript = (content) => {
    const script = document.createElement('script');
    script.type = "application/javascript";
    script.innerHTML = content;
    document.head.appendChild(script);
};