import React, { useEffect, useState } from 'react'
// import Nav from '../components/Nav'
import Mission from '../components/Mission'
import bannerCardImg from '../assets/images/banner-card-img.png';
import Footer from '../components/HomeFooter'
import FactoryCarousal from '../components/FactoryCarousal'
import Partners from '../components/Partners'
import OurClients from '../components/OurClients'
import OurServices from '../components/OurServices'
import '../assets/css/Home.css';
import bannerVideo from '../assets/video/loop-nature_web-compressed.mp4'
import Nav from '../components/Nav'
import HomeFooter from '../components/HomeFooter'
import { layoutLang } from '../assets/languages/LayoutLang';
import { homeLang } from '../assets/languages/HomeLang';
import { useTranslation } from 'react-i18next';

// v2
import stamp_logo from '../assets/images/stamp_logo.png'
import bestSeller_rewe from '../assets/images/bestSeller_rewe.png'
import bestSeller_chocolate from '../assets/images/bestSeller_chocolate.png'
import bestSeller_beeswe from '../assets/images/bestSeller_beeswe.png'
import bestSeller_bee from '../assets/images/bestSeller_bee.png'
import flow from '../assets/images/flow.png'
import award1 from '../assets/images/award1.png'
import award2 from '../assets/images/award2.png'
import award3 from '../assets/images/award3.png'
import testimonial_person from '../assets/images/testimonial_person.png'
import testimonial_person2 from '../assets/images/testimonial_person2.png'
import testimonial_person3 from '../assets/images/testimonial_person3.png'
import screen from '../assets/images/screen_new.png'
import genusslab from '../assets/images/genusslab.png'
import imkerei from '../assets/images/imkerei.png'
import kreation from '../assets/images/kreation.png'
import landwirtschaft from '../assets/images/landwirtschaft.png'
import saemerei from '../assets/images/saemerei.png'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import homeBgCampain from '../assets/images/homeBgCampains.png'
import DownloadCatalog from '../assets/pdf/DownloadCatalog_new.pdf'
import mobile from '../assets/video/header-video-mobile-final.mp4'
import desktop from '../assets/video/header-video-desktop-final.mp4'


export default function Home() {
    const { t, i18n } = useTranslation('home');
    const { t: tHome, i18n: i18nHome } = useTranslation('home');
    const [data, setData] = useState(null)


    const currentLanguage = i18nHome.language;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/brief_about/list?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        
        fetchData();

    }, [currentLanguage])

    console.log('hi',data)

    return (
        <>
            {/* <Nav /> */}
            <div className='position-relative' style={{ height: '670px', width: '100%' }}>
                <video className="d-md-none" width="100%" autoPlay loop muted playsInline style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                    <source src={bannerVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <video class="d-none d-md-block" width="100%" autoPlay loop muted playsInline style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                    <source class="skip-lazy" src={bannerVideo} type="video/mp4" /> Your browser does not support the video tag.
                </video>


                <div className='container'>
                    <div className='HomeBannerIcons' style={{
                        width: '12%',
                        position: 'absolute',
                        top: '570px',
                        right: '7%',
                    }}>
                        <ul class="list-unstyled d-flex justify-content-between text-line">
                            <li class="d-inline text-center " >
                                <span className='rounded-circle d-flex align-items-center justify-content-center' style={{ border: '2px solid white', width: '50px', height: '50px', padding: '35%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill='white' d="M279.1 288l14.2-92.7h-88.9v-60.1c0-25.4 12.4-50.1 52.2-50.1h40.4V6.3S260.4 0 225.4 0c-73.2 0-121.1 44.4-121.1 124.7v70.6H22.9V288h81.4v224h100.2V288z" /></svg>
                                </span>
                            </li>
                            <li class="d-inline">
                                <span className='rounded-circle d-flex align-items-center justify-content-center' style={{ border: '2px solid white', width: '50px', height: '50px', padding: '35%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='white' d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                                </span>
                            </li>
                            <li class="d-inline">
                                <span className='rounded-circle d-flex align-items-center justify-content-center' style={{ border: '2px solid white', width: '50px', height: '50px', padding: '35%' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill='white' d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                                </span>
                            </li>

                        </ul>

                    </div>

                    {/* modal for Let's Enquiry */}

                    <div class="modal fade" id="exampleModalEnquiry" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content bg-dark text-white">

                                <div class="modal-body">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{
                                        float: 'right',
                                        filter: 'invert(1) grayscale(100)'
                                    }}></button>
                                    <h5 class="modal-title text-center p-3 pb-3" id="exampleModalLabel">Your contact with us !</h5>

                                    <div className='row'>
                                        <div className='col-6'>
                                            <input type='text' className='bg-transparent form-control text-white m-1 mb-3 placeholder-white' placeholder='Name' />
                                        </div>

                                        <div className='col-6'>
                                            <input type='text' className='bg-transparent form-control text-white m-1 placeholder-white' placeholder='Email' />
                                        </div>
                                        <div className='col-12'>
                                            <textarea className='bg-transparent form-control text-white m-1 mb-3 placeholder-white' placeholder='Your Message'></textarea>
                                        </div>

                                        <button className='btn  btn-md w-50 rounded-pill mx-auto m-2 text-white' style={{ backgroundColor: '#CBD62E' }}>Submit</button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    {/* model for Watch now video */}

                    <div class="modal fade" id="exampleModalVideo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered">
                            <div class="modal-content bg-dark text-white">

                                <div class="modal-body">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{
                                        float: 'right',
                                        filter: 'invert(1) grayscale(100)'
                                    }}></button>
                                    {/* <h5 class="modal-title text-center p-3 pb-3 display" id="exampleModalLabel">Your contact with us !</h5> */}

                                    <video loop="true" autoplay="autoplay" muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                                        <source src={bannerVideo} type="video/mp4" />
                                        <source src={bannerVideo} type="video/ogg" />

                                        Your browser does not support the video tag.
                                    </video>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <div className='container section-padding'>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='p-5'>
                            <img src={stamp_logo} className='' style={{ width: '85%' }} alt='Stamp Logo' />
                        </div>
                    </div>
                    {/* <div className='col-md'>
                        <div className=' pt-5 text-white'>
                            <h1>{tHome("banner").title}</h1>
                            <p className='mb-3 fs-5'>
                               {tHome("banner").title_content1}
                            </p>
                            <p className='mb-4 fs-5'>{tHome("banner").title_content2}</p>
                            <button className='btn rounded-0' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><HashLink to="/about" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >{tHome("banner").button}</HashLink></button>
                        </div>
                    </div> */}

                    <div className='col-md'>
                        <div className=' pt-5 text-white'>
                            <h1>{data?.data[0].title}</h1>
                            <p className='mb-3 fs-5' dangerouslySetInnerHTML={{ __html: data?.data[0].description }}>
                            
                            </p>
                            <button className='btn rounded-0' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><HashLink to="/about" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >{tHome("banner").button}</HashLink></button>
                        </div>
                    </div>
                </div>
            </div>

            {/* best seller */}
            <div className=''>
                <hr style={{ border: '2px solid #CBD62E', width: '8%', opacity: 1 }} className='m-0 mx-auto my-3' />
                <h2 className='text-center p-0' style={{ color: '#CBD62E' }}>BESTSELLERS</h2>
                <FactoryCarousal />
            </div>

            {/*  */}

            {/* NACHHALTIGKEITS MANUFAKTUR */}
            <div className='container section-padding'>
                <hr style={{ border: '2px solid #CBD62E', width: '8%', opacity: 1 }} className='m-0 mx-auto my-3' />
                <div>
                    <h2 className='text-center p-2' style={{ color: '#CBD62E', textTransform: 'uppercase' }}>{tHome("sustainable_manufactory_section").title}</h2>
                    <p className='text-white text-center fs-5'>{tHome("sustainable_manufactory_section").content}</p>

                    <div className='text-center py-4 ps-md-5 pe-md-5'>
                        <div className='container'>
                            <div className=' d-flex justify-content-between flex-wrap align-items-center'>
                                <div className=''>
                                    <img src={screen} className='' style={{ width: '37px' }} alt='Screen' />
                                    <h4 className='text-white text-uppercase'>{tHome("sustainable_manufactory_section").heading1}</h4>
                                </div>
                                <div className=''>
                                    <img src={genusslab} className='' style={{ width: '37px' }} alt='Screen' />
                                    <h4 className='text-white text-uppercase'>{tHome("sustainable_manufactory_section").heading2}</h4>
                                </div>
                                <div className=''>
                                    <img src={imkerei} className='' style={{ width: '37px' }} alt='Screen' />
                                    <h4 className='text-white text-uppercase'>{tHome("sustainable_manufactory_section").heading3}</h4>
                                </div>
                                <div className=''>
                                    <img src={kreation} className='' style={{ width: '37px' }} alt='Screen' />
                                    <h4 className='text-white text-uppercase'>{tHome("sustainable_manufactory_section").heading4}</h4>
                                </div>
                                <div className=''>
                                    <img src={landwirtschaft} className='' style={{ width: '37px' }} alt='Screen' />
                                    <h4 className='text-white text-uppercase'>{tHome("sustainable_manufactory_section").heading5}</h4>
                                </div>
                                <div className=''>
                                    <img src={saemerei} className='' style={{ width: '37px' }} alt='Screen' />
                                    <h4 className='text-white text-uppercase'>{tHome("sustainable_manufactory_section").heading6}</h4>
                                </div>
                            </div>
                        </div>

                        <button className='btn rounded-0 mt-5' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><HashLink to="/microfactories#beeslove" className="nav-link fs-6 ps-3 pe-3 p-1 fw-bold text-dark" aria-disabled="true" >{tHome("sustainable_manufactory_section").button}</HashLink></button>
                    </div>

                </div>
            </div>

            {/* Catalog Section */}
            <div className='container section-padding my-5 catalog-container'>
                <div className='row'>
                    <div className='col-md-5 col-12'>
                        <div className='catlog p-5   text-white '>
                            <h1 className='display-4 ps-1 mt-3'>Zogi Nature <br />Katalog 2024</h1>
                            {/* <button className='btn btn-lg text-dark catalog-download-btn my-3 ' style={{ backgroundColor: '#CBD62E' }}><b>Download</b></button> */}
                            <a href={DownloadCatalog} target="_blank" rel="noopener" download className='btn btn-lg text-dark catalog-download-btn my-3 ' style={{ backgroundColor: '#CBD62E' }}><b>Download</b></a>

                        </div>
                    </div>
                    <div className='col-md-7 col-12'>
                        <iframe className='catalog-iframe' src='https://www.yumpu.com/de/embed/view/V8bFRcYEUveQWwSM' style={{ width: '100%', height: '350px' }} />
                    </div>
                </div>
            </div>

            {/* Partners Section */}
            <div className='section-padding pt-3'>
                <OurClients />
                <div className='text-center'>
                    {/* <Link to="/zogi_nature/partners" className='text-decoration-none text-dark '><button className='btn btn-md rounded-0 text-dark p-3 ps-3 pe-3 mt-4' style={{ backgroundColor: '#CBD62E' }}><b>Partner</b></button></Link> */}
                    <button className='btn rounded-0 mt-5' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="/partners" className="nav-link fs-6 ps-3 pe-3 p-1 fw-bold text-dark" aria-disabled="true" >{t("our_partners_carousal").button}</Link></button>
                </div>
            </div>

            {/* testimonial section*/}
            <div id="carouseltestiminial" class="carousel slide mt-4 section-padding" >
                <div class="carousel-inner rounded-0">
                    <div class="carousel-item active" data-bs-interval="10000">
                        <div className='container py-4'>
                            <div className='row testimonial-bg p-3'>
                                <div className='col-lg-8 text-lg-start text-center text-white'>
                                    <div className=''>
                                        <p className='fs-5 p-lg-5 p-3'>
                                            »…eine nachhaltige und zukunftsorientierte Partnerschaft, welche sich für die Biodiversität und Artenvielfalt in Karlsruhe und der Region einsetzt. Hiermit stärken wir eine unserer drei Säulen von KSC TUT GUT. Deshalb befindet sich die Umwelt im Fokus der Kooperation.«
                                        </p>
                                        <div className='fs-5 ps-lg-5 ps-3'>
                                            <p style={{ color: '#CBD62E' }}><b>Luca Maibaum</b></p>
                                            <p>Head of B2C & CSR Karlsruher Sport-Club</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg d-flex justify-content-center align-items-center text-center'>
                                    <img src={testimonial_person} className='w-75 p-lg-4 p-3' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item" data-bs-interval="2000">
                        <div className='container py-4'>
                            <div className='row testimonial-bg2 p-3'>
                                <div className='col-lg-8 text-lg-start text-center text-white'>
                                    <div className=''>
                                        <p className='fs-5 p-lg-5 p-3'>
                                            »Würde die Honigbiene als Bestäuber fehlen, blieben unsere Supermarktregale leer. Darum engagieren wir uns mit unserer Patenschaft für den Erhalt der Artenvielfalt«
                                        </p>
                                        <div className='fs-5 ps-lg-5 ps-3'>
                                            <p style={{ color: '#CBD62E' }}><b>Sabine Stachorski </b></p>
                                            <p>Pressesprecherin REWE Südwest</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg d-flex justify-content-center align-items-center text-center'>
                                    <img src={testimonial_person2} className='w-75 p-lg-4 p-3' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className='container py-4'>
                            <div className='row testimonial-bg3 p-3'>
                                <div className='col-lg-8 text-lg-start text-center text-white'>
                                    <div className=''>
                                        <p className='fs-5 p-lg-5 p-3'>
                                            {tHome("testimonial_section").content3}
                                        </p>
                                        <div className='fs-5 ps-lg-5 ps-3'>
                                            <p style={{ color: '#CBD62E' }}><b>{tHome("testimonial_section").name3}</b></p>
                                            <p>{tHome("testimonial_section").role3}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg d-flex justify-content-center align-items-center text-center'>
                                    <img src={testimonial_person3} className='w-75 p-lg-4 p-3' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouseltestiminial" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouseltestiminial" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            {/* our reward */}
            <div className='container section-padding pt-5'>
                <hr style={{ border: '2px solid #CBD62E', width: '8%', opacity: 1 }} className='m-0 mx-auto my-3' />
                <h2 className='text-center pb-4' style={{ color: '#CBD62E', textTransform: 'uppercase' }}>{tHome("rewards_section").title}</h2>
                <div className="w-75  mx-auto row justify-content-center">
                    <div className="col-md-4 col-lg-4 col-xl-4 text-center">
                        <img src={award1} className="img-fluid " alt="Award 1" />
                    </div>
                    <div className="col-md-4 col-lg-4 col-xl-4 text-center">
                        <img src={award2} className="img-fluid w-50" alt="Award 2" />
                    </div>
                    <div className="col-md-4 col-lg-4 col-xl-4 text-center">
                        <img src={award3} className="img-fluid" alt="Award 3" />
                    </div>
                </div>
            </div>


            {/* <HomeFooter /> */}
        </>
    )
}
